.overlay,
.overlay-file {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(8px) brightness(100%);
    backdrop-filter: blur(8px) brightness(100%);
    background-color: #3440534c;
    mix-blend-mode: normal;
    z-index: 10000;
}

.hide {
    display: none;
}

.add-team-form {
    width: 550px;
    min-height: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30vh;
    margin-left: -225px;
    background: var(--white) !important;
    padding: 20px;
    border-radius: 10px;
}

.add-btn {
    height: 40px;
    background-color: #7f56d9 !important;
}

.form-spacer {
    margin-bottom: 30px;
    padding-left: 0 !important;
}

.add-team-button {
    margin-bottom: 30px;
    padding-left: 0 !important;
    color: #7f56d9;
}

.cancel {
    background: var(--white) !important;
    color: black;
    text-align: center;
    width: 100% !important;
    border-color: var(--mischka) !important
}

.single-line-title {
    margin-left: -10px;
}
.add-form-p {
    margin-left: 10px;
}

#teamName {
    margin-top: 20px;
}

.add-team-header {
    margin: 20px 10px -30px 0;
}

.add-form-content-container {
    margin: 20px 10px -30px 0;
}

.file-icon-create-team-form {
    top: 9px !important;
    padding: 14px !important;
    font-size: 20px !important;
}
.loading {
    width: 80px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}