.add-form-h2 {
  padding-top: 10px;
}
.add-form-title {
  margin-left: 10px;
}

.add-form-header {
  margin: 20px 10px;
}

.dashboard-add-dropdown,
.dashboard-add-dropdown-menu {
  width: 100% !important;
  border: var(--mischka);
}

.dashboard-add-dropdown > button {
  background: var(--white) !important;
  color: grey;
  text-align: left;
  width: 100% !important;
  border-color: var(--mischka) !important;
}

.add-form > .form-group {
  margin-bottom: 10px;
}
.btn:hover {
  color: black !important;
}

.file-icon-add-form {
  top: 9px !important;
  padding: 16px !important;
  left: 0px !important;
}

.file-icon {
  left: 0px !important;
}

.check-latest {
  top: 5px;
  position: relative;
  left: 44px;
}
.check-latest-lable {
  top: 5px;
  position: relative;
  left: 60px;
}
.form-check-input:checked {
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}

.add-user-badge {
  color: #7e56d8;
  background-color: #f4ebff;
  margin: 10px 5px 10px 0px;

  padding: 10px 10px;
}