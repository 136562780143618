.login-container {
  height: 100vh;
}
.login-form {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--font-family-inter) !important;
  max-width: 400px !important;
}

.login-form h1,
.login-form p {
  text-align: center;
}

#passwordInputPassword {
  margin-bottom: 15px;
}

.login-btn {
  /* margin-top: 15px; */
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.reg-btn {
  margin-top: 15px;
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.reg-btn-secondary {
  margin-top: 15px;
  width: 100%;
  background-color: #344054;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #344054;
  mix-blend-mode: normal;
  overflow: hidden;
}

.get-started > a {
  color: var(--fuchsia-blue) !important;
}

.login-logo {
  position: relative;
  top: 50vh;
  left: 50%;
  margin-left: -100px;
  margin-top: -32px;
}

.processing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);
}

.loadingImg {
  position: absolute;
  top: 50vh;
  left: 50vw;
  margin-left: -40px;
  margin-top: -40px;
}