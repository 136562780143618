@import url(https://fonts.googleapis.com/css?family=Inter:400,500,600);
@import url(https://px.animaapp.com/621fd079114f27e7693bd4ab.621fd07b5ad79b5f63622c5e.T06oT8w.hcp.png);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #101828;
}

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */

.screen textarea:focus,
.screen input:focus {
  outline: none;
}

.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.screen div {
  -webkit-text-size-adjust: none;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden !important;
}

.left-panel {
  background: #101828;
  height: 100vh;
}

.container-fluid {
  padding-right: 0px !important;
  overflow: hidden;
}

.right-panel {
  background: #fff;
  height: 100vh;
}
.login-container {
  height: 100vh;
}
.login-form {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--font-family-inter) !important;
}

.login-form h1,
.login-form p {
  text-align: center;
}

.login-btn {
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.login-button {
  color: var(--fuchsia-blue)  !important;
}

.email-label,
.password-label,
.get-started {
  text-align: left !important;
}

.email-label small,
.password-label small,
.login-form p {
  color: grey;
}

.get-started {
  margin-top: 20px !important;
  font-size: 12px;
}

.get-started a {
  color: var(--fuchsia-blue);
  text-decoration: underline;
}
/* dashboard styles */
.dashboard-container {
  background: #101828;
  height: 100vh;
}

.left-panel-dashboard {
  height: 100vh;
}

.right-panel-dashboard {
  margin-top: 15px;
  border-radius: 45px 0px 0px;
  background: #fff;
  height: 100vh;
}
.dashdoard-main-header {
  padding: 35px;
}

tr.file-row {
  border-radius: 10px !important;
  overflow: hidden !important;
}

.file-checkbox {
  text-align: center;
}

.filter-col {
  margin-top: 30px;
  margin-bottom: 20px;
}

.media-body > p {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.list-media-body > h5 {
  font-size: var(--font-size-m);
  padding-top: 8px;
}

.list-media-body > p {
  font-size: var(--font-size-m);
}

.filter-item > button {
  background: var(--blue-chalk) !important;
  border-color: #fff !important;
  color: #7f56d9;
}

.filters-dropdown {
  border-color: grey !important;
  background-color: transparent !important;
  color: grey;
}
.filters-dropdown:hover {
  color: grey;
}
.dropdown:is(.show) > .filters-dropdown {
  color: grey;
}
.filters-dropdown:after {
  display: none;
}

.form-status-up-to-date {
  background-color: var(--narvik);
  color: grey;
  padding: 7px;
}

.form-status-pending {
  background-color: var(--concrete);
  color: grey;
  padding: 7px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--white-lilac) !important;
}

.card {
  border-radius: 0.65rem !important;
}

.file-view-more {
  cursor: pointer;
}

.stat-up {
  color: green;
}

.stat-down {
  color: red;
}

.avatars-OYw5hJ {
  background-color: transparent;
  height: 24px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 124px;
}

.avatar-wrap-0 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-1 {
  background-color: transparent;
  height: 24px;
  left: 20px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-2 {
  background-color: transparent;
  height: 24px;
  left: 40px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-wrap-3 {
  background-color: transparent;
  height: 24px;
  left: 60px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-4 {
  background-color: transparent;
  height: 24px;
  left: 80px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-wrap-5 {
  background-color: transparent;
  height: 24px;
  left: 100px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-LcVv8q {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-f0f9Ls {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-4I2ic3 {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-UHrqfV {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}
.avatar-hLXsUF {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-B6y5ns {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-Vx0t4z {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-mTcO5l {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-PbkabT {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-yc1H1O {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-bfxrUs {
  background-color: var(--magnolia);
  border-radius: 200px;
  height: 28px;
  left: 98px;
  mix-blend-mode: normal;
  position: absolute;
  top: -2px;
  width: 28px;
}

.text-i2ip3A {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: 18px;
  mix-blend-mode: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
  width: 24px;
}

.dashboard-nav {
  height: 100vh;
  padding-top: 10vh;
  color: var(--concrete) !important;
}

.dashboard-nav > a {
  color: var(--concrete) !important;
}

.dashboard-nav > a > i {
  padding-right: 10px;
}

.active {
  background-color: var(--oxford-blue) !important;
}

.nav-card-dashboard {
  background-color: var(--oxford-blue);
}

.hr {
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
  border: 0;
  border-top: 1px solid var(--concrete) !important;
}

.avatar-dashboard-nav {
  width: 3vmin;
}

.avatar-user-details {
  /* padding-left: 4vmin; */
  font-size: 0.7rem;
}

/* .avatar-user-logout-detail, .logout {
    height: 1rem;
} */

.avatar-user-logout-detail > a {
  vertical-align: middle !important;
  color: var(--concrete) !important;
}

.dashboard-nav-search {
  background: var(--oxford-blue) !important;
  border-color: var(--oxford-blue) !important;
  color: var(--concrete) !important;
}

.dashboard-nav-search::-webkit-input-placeholder {
  color: var(--concrete) !important;
  content: '\F095';
}

.dashboard-nav-search::placeholder {
  color: var(--concrete) !important;
  content: '\F095';
}

/* 

.dashboard-add-dropdown, .dashboard-add-dropdown-menu {
    width:100% !important;
    border: var(--mischka);
}

.dashboard-add-dropdown > button {
    background: var(--white) !important;
    color: grey;
    text-align: left;
    width:100% !important;
    border-color: var(--mischka) !important;
} */

.btn-danger {
  border-color: var(--mischka) !important;
}

.dashboard-add-dropdown > button:after {
  /* position: relative !important;
    right: 0px !important; */
  text-align: right;
  float: right;
  margin-top: 9px;
}

.submit {
  width: 100% !important;
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}

.add-file-media-block {
  padding-left: 15px !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

:root {
  --black: #000000;
  --blue-chalk: #f4ebff;
  --concrete: #f2f3f6;
  --fuchsia-blue: #7e56d8;
  --gallery: #eaecf0;
  --magnolia: #f9f5ff;
  --mirage: #0f1728;
  --mischka: #cfd4dc;
  --narvik: #ebfdf2;
  --oxford-blue: #344053;
  --pale-sky: #667084;
  --salem: #027947;
  --storm-gray: #667085;
  --white: #ffffff;
  --white-lilac: #f8f9fb;

  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 18px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 36px;

  --font-family-inter: 'Inter', Helvetica;
}
.inter-normal-pale-sky-14px {
  color: #667084;
  color: var(--pale-sky);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-pale-sky-16px {
  color: #667084;
  color: var(--pale-sky);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-oxford-blue-14px {
  color: #344053;
  color: var(--oxford-blue);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-mirage-14px {
  color: #0f1728;
  color: var(--mirage);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-concrete-16px {
  color: #f2f3f6;
  color: var(--concrete);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-mirage-14px {
  color: #0f1728;
  color: var(--mirage);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-fuchsia-blue-12px {
  color: #7e56d8;
  color: var(--fuchsia-blue);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 12px;
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-pale-sky-12px {
  color: #667084;
  color: var(--pale-sky);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 12px;
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-salem-12px {
  color: #027947;
  color: var(--salem);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 12px;
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-14px {
  color: #ffffff;
  color: var(--white);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-storm-gray-16px {
  color: #667085;
  color: var(--storm-gray);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-mirage-16px {
  color: #0f1728;
  color: var(--mirage);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-mirage-16px {
  color: #0f1728;
  color: var(--mirage);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.inter-semi-bold-mirage-36px {
  color: #0f1728;
  color: var(--mirage);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 36px;
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.inter-medium-pale-sky-14px {
  color: #667084;
  color: var(--pale-sky);
  font-family: 'Inter', Helvetica;
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.border-1-5px-white {
  border: 1.5px solid #ffffff;
  border: 1.5px solid var(--white);
}

.border-1px-mischka {
  border: 1px solid #cfd4dc;
  border: 1px solid var(--mischka);
}

.border-1px-fuchsia-blue {
  border: 1px solid #7e56d8;
  border: 1px solid var(--fuchsia-blue);
}

.border-2px-white {
  border: 2px solid #ffffff;
  border: 2px solid var(--white);
}

.border-1px-gallery {
  border: 1px solid #eaecf0;
  border: 1px solid var(--gallery);
}

.login-container {
  height: 100vh;
}
.login-form {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--font-family-inter) !important;
  max-width: 400px !important;
}

.login-form h1,
.login-form p {
  text-align: center;
}

#passwordInputPassword {
  margin-bottom: 15px;
}

.login-btn {
  /* margin-top: 15px; */
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.reg-btn {
  margin-top: 15px;
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.reg-btn-secondary {
  margin-top: 15px;
  width: 100%;
  background-color: #344054;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #344054;
  mix-blend-mode: normal;
  overflow: hidden;
}

.get-started > a {
  color: var(--fuchsia-blue) !important;
}

.login-logo {
  position: relative;
  top: 50vh;
  left: 50%;
  margin-left: -100px;
  margin-top: -32px;
}

.processing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.loadingImg {
  position: absolute;
  top: 50vh;
  left: 50vw;
  margin-left: -40px;
  margin-top: -40px;
}
.right-panel-dashboard {
  margin-top: 15px;
  border-radius: 45px 0px 0px;
  background: #fff;
  min-height: 100vh;
  height:100%
}

.dashboard-main-header {
  padding: 35px;
}

.dashboard-card {
  width: 100% !important;
}

.small-amount {
  color: gray;
}
/*.dashboard {*/
/*    overflow-y: scroll !important;*/
/*}*/

.overlay,
.overlay-file {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  backdrop-filter: blur(8px) brightness(100%);
  background-color: #3440534c;
  mix-blend-mode: normal;
  z-index: 10000;
}

.hide {
  display: none;
}

.add-form {
  width: 650px;
  min-height: 550px;
  /* max-height: 550px; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30vh;
  margin-left: -325px;
  background: var(--white) !important;
  padding: 20px;
  border-radius: 10px;
}

.add-btn {
  height: 40px;
  background-color: #7f56d9 !important;
}

.add-btn-team {
  height: 40px;
  background-color: #7f56d9 !important;
}

.add-btn-user {
  height: 40px;
  background: var(--white) !important;
  color: black;
  text-align: center;
  width: 100% !important;
  border-color: var(--mischka) !important
}
.add-form-h2 {
  padding-top: 10px;
}
.add-form-title {
  margin-left: 10px;
}

.add-form-header {
  margin: 20px 10px;
}

.dashboard-add-dropdown,
.dashboard-add-dropdown-menu {
  width: 100% !important;
  border: var(--mischka);
}

.dashboard-add-dropdown > button {
  background: var(--white) !important;
  color: grey;
  text-align: left;
  width: 100% !important;
  border-color: var(--mischka) !important;
}

.add-form > .form-group {
  margin-bottom: 10px;
}
.btn:hover {
  color: black !important;
}

.file-icon-add-form {
  top: 9px !important;
  padding: 16px !important;
  left: 0px !important;
}

.file-icon {
  left: 0px !important;
}

.check-latest {
  top: 5px;
  position: relative;
  left: 44px;
}
.check-latest-lable {
  top: 5px;
  position: relative;
  left: 60px;
}
.form-check-input:checked {
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}

.add-user-badge {
  color: #7e56d8;
  background-color: #f4ebff;
  margin: 10px 5px 10px 0px;

  padding: 10px 10px;
}
.overlay,
.overlay-file {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  backdrop-filter: blur(8px) brightness(100%);
  background-color: #3440534c;
  mix-blend-mode: normal;
  z-index: 10000;
}

.hide {
  display: none;
}

.add-team-form {
  width: 550px;
  min-height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30vh;
  margin-left: -225px;
  background: var(--white) !important;
  padding: 20px;
  border-radius: 10px;
}

.add-btn {
  height: 40px;
  background-color: #7f56d9 !important;
}

.form-spacer {
  margin-bottom: 30px;
  padding-left: 0 !important;
}

.add-team-button {
  margin-bottom: 30px;
  padding-left: 0 !important;
  color: #7f56d9;
}

.cancel {
  background: var(--white) !important;
  color: black;
  text-align: center;
  width: 100% !important;
  border-color: var(--mischka) !important
}

.single-line-title {
  margin-left: -10px;
}
.add-form-p {
  margin-left: 10px;
}

#teamName {
  margin-top: 20px;
}

.add-team-header {
  margin: 20px 10px -30px 0;
}

.add-form-content-container {
  margin: 20px 10px -30px 0;
}

.file-icon-create-team-form {
  top: 9px !important;
  padding: 14px !important;
  font-size: 20px !important;
}
.overlay,
.overlay-file {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(8px) brightness(100%);
    backdrop-filter: blur(8px) brightness(100%);
    background-color: #3440534c;
    mix-blend-mode: normal;
    z-index: 10000;
}

.hide {
    display: none;
}

.add-team-form {
    width: 550px;
    min-height: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30vh;
    margin-left: -225px;
    background: var(--white) !important;
    padding: 20px;
    border-radius: 10px;
}

.add-btn {
    height: 40px;
    background-color: #7f56d9 !important;
}

.form-spacer {
    margin-bottom: 30px;
    padding-left: 0 !important;
}

.add-team-button {
    margin-bottom: 30px;
    padding-left: 0 !important;
    color: #7f56d9;
}

.cancel {
    background: var(--white) !important;
    color: black;
    text-align: center;
    width: 100% !important;
    border-color: var(--mischka) !important
}

.single-line-title {
    margin-left: -10px;
}
.add-form-p {
    margin-left: 10px;
}

#teamName {
    margin-top: 20px;
}

.add-team-header {
    margin: 20px 10px -30px 0;
}

.add-form-content-container {
    margin: 20px 10px -30px 0;
}

.file-icon-create-team-form {
    top: 9px !important;
    padding: 14px !important;
    font-size: 20px !important;
}
.loading {
    width: 80px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}
.workflows-tr > td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.closeBtn {
    position: absolute;
    top: 20px;
    right: 30px;
}

.workflow-file-icon {
    background: var(--blue-chalk);
    padding: 7px 12px !important;
    border-radius: 73px !important;
    color: #7f56d9 !important;
    font-size: 11px !important;
    position: relative;
    top: 9%;
    left: 25px !important;
}

.workflow-task-icon {
    background: var(--blue-chalk);
    padding: 7px 10px !important;
    border-radius: 73px !important;
    color: #7f56d9 !important;
    font-size: 12px !important;
    position: relative;
    top: 9%;
    left: 25px !important;
}

.workflow-description {
    max-width: 540px;
    font-size: 14px;
    color: #667085;
}
.workflow-status {
    background-color: var(--narvik);
    color: darkgray !important;
    padding: 7px;
    text-decoration: none;
}

.workflow-input {
    margin-bottom: 10px;
}

.workflows-title {
    padding-left: 24px;
}

.add-step-submit {
    background-color: #667084 !important;
    border-color: #667084 !important;
}

.add-form-header-workflow {
    margin-left: -10px;
}

.completed {
    background-color: var(--oxford-blue) !important;
}

svg.Mui-active > circle {
    background-color: #667084 !important;
}

.workflow-save {
    flex: fit-content 1;
    flex-align: flex-end;
    position: absolute;
    bottom: 10px;
    width: 100% !important;
}

.btn-workflow-step {
    flex: fit-content 1;
    border-radius: 6px;
    color: #7f56d9 !important;
    width: 100%;
    text-align: left;
}

.btn-workflow-step:hover {
    border-radius: 6px;
    background: #F9F5FF;
    color: #fff;
}

.btn-workflow-step-more {
    color: #7f56d9 !important;
}

.add-step-cancel {
    background: none !important;
    border-color: var(--oxford-blue) !important;
    color: var(--oxford-blue) !important;
}

.delete-icon:hover,
.edit-icon {
  cursor: pointer;
  color: #101828 !important;
  text-shadow: 2px 2px whitesmoke;
}

thead > tr > th {
  font-size: 12px;
  color: grey;
}

.file-icon {
  background: var(--blue-chalk);
  padding: 1rem 1rem;
  border-radius: 45px;
  color: #7f56d9;
  font-size: 19px;
  position: relative;
  top: 20px;
  left: -5px;
}

.filter-item {
  margin-right: 10px;
}

.file-disc {
  letter-spacing: 0px;
  line-height: 20px;
  mix-blend-mode: normal;
  text-align: left;
  white-space: nowrap;
  /* width: auto; */
  margin-top: 16px;
}

.file-icon {
  background: var(--blue-chalk);
  padding: 13px;
  border-radius: 45px;
  color: #7f56d9;
  font-size: 16px;
  position: relative;
  top: 9%;
  left: 25px !important;
}

.file-icon-view {
  top: 19%;
}

.btn-light {
  border: 1px solid #ced4da;
  color: #000 !important;
}

.file-version-view-icon {
  left: 0px !important;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 30px;
}

.header-nav {
  background-color: #101828 !important;
}

.left-panel {
  /*overflow: hidden;*/
}
.nav-spacer {
  height: 3rem;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.nav-spacer-top {
  height: 3rem;
}
.bi {
  margin-right: 6px;
}

.active {
  color: var(--concrete) !important;
}

a {
  color: var(--concrete) !important;
}

.nav-profile {
  margin-top: 15px;
  margin-bottom: 20px;
}

.logout {
  font-size: 1rem !important;
  /*position: absolute;*/
  /*left: 210px;*/
  /*bottom: 25px;*/
  color: #eaecf0;
}

.avatar-dashboard-nav {
  border-radius: 31px;
  /*position: absolute;*/
  /*bottom: 20px;*/
  margin-right: 5px;
}

.avatar-user-details {
  /*padding-bottom: 5px;*/
  margin-left: 5px;
}

.avatar-user-details > small {
  font-size: 0.5rem;
}

.dropdown-item {
  color: grey !important;
}

.nav-profile {
  position: fixed;
  bottom: 0;
  left: 0;
}

.nav-tabs {
  border-bottom: 1px solid #f2f4f7;
  margin-bottom: 40px;
}

.nav-tabs .nav-item div {
  color: #667085 !important;
  background-color: #fff !important;
  border-color: #fff #fff #f2f4f7;
}

.nav-tabs .nav-item .active,
.nav-tabs .nav-item div:hover {
  color: #6941c6 !important;
  background-color: #fff !important;
  border-color: #fff #fff #6941c6;
}

hr {
  background-color: #cfd4dc;
}

.hr-feild-list {
  margin-bottom: 60px;
}

.input-settings {
  border-radius: 5px;
  border-top-color: #f2f4f7 !important;
  border-right-color: #f2f4f7 !important;
  border-bottom-color: #f2f4f7 !important;
  border-left-color: #f2f4f7 !important;
}
.settings-input {
  width: 100%;
  padding: 5px;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  border-top-color: #667085 !important;
  border-right-color: #667085 !important;
  border-bottom-color: #667085 !important;
  border-left-color: #667085 !important;
}
.country-toggle {
  background-color: #ffffff !important;
  width: 100%;
  text-align: left !important;
  color: #101828;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  border-top-color: #667085 !important;
  border-right-color: #667085 !important;
  border-bottom-color: #667085 !important;
  border-left-color: #667085 !important;
}
.country-item {
  width: 100% !important;
}
.country-dropdown {
  position: relative !important;
  text-align: left !important;
}
.pWordInputs {
  margin-bottom: 50px;
}
.pWordBtn {
  /*width: 80px;*/
}

.pWordBtn-cancel {
  background-color: #eaecf0;
  color: #0f1728;
}

.pWordBtn-save,
.pWordBtn-save:active,
.pWordBtn-save:hover {
  background-color: #7e56d8;
  margin-left: 10px;
  color: #ffffff;
}
.user-avatar-td {
  width: 60px;
}
.user-avatar {
  border-radius: 31px;
  width: 4rem;
}

.user-avatar-img {
  width: 4rem;
}

@media screen and (max-width: 1000px) {
  .user-avatar-img {
    width: 3rem;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--bs-table-bg) !important;
}
.th-padding {
  padding: 0 35px !important;
}
ul.pill-ul {
  list-style: none;
  margin: 16px auto;
}

.pill {
  border-radius: 15px;
  background-color: #f2f4f7;
  padding: 5px 0;
  margin: 0 25px;
  text-align: center;
}

.pill-active {
  background-color: #ecfdf3;
  color: #027a48 !important;
}

.pill-team {
  background-color: #f9f5ff;
  color: #6941c6 !important;
}

.pill-active::before {
  content: '\2022';
  color: #027a48 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.pill-undefined::before {
  content: '\2022';
  color: #344054 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.user-table-space {
  margin-top: 35px;
}
