html,
body {
  overflow-x: hidden !important;
}

.left-panel {
  background: #101828;
  height: 100vh;
}

.container-fluid {
  padding-right: 0px !important;
  overflow: hidden;
}

.right-panel {
  background: #fff;
  height: 100vh;
}
.login-container {
  height: 100vh;
}
.login-form {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--font-family-inter) !important;
}

.login-form h1,
.login-form p {
  text-align: center;
}

.login-btn {
  width: 100%;
  background-color: var(--fuchsia-blue);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #1018280d;
  mix-blend-mode: normal;
  overflow: hidden;
}

.login-button {
  color: var(--fuchsia-blue)  !important;
}

.email-label,
.password-label,
.get-started {
  text-align: left !important;
}

.email-label small,
.password-label small,
.login-form p {
  color: grey;
}

.get-started {
  margin-top: 20px !important;
  font-size: 12px;
}

.get-started a {
  color: var(--fuchsia-blue);
  text-decoration: underline;
}
/* dashboard styles */
.dashboard-container {
  background: #101828;
  height: 100vh;
}

.left-panel-dashboard {
  height: 100vh;
}

.right-panel-dashboard {
  margin-top: 15px;
  border-radius: 45px 0px 0px;
  background: #fff;
  height: 100vh;
}
.dashdoard-main-header {
  padding: 35px;
}

tr.file-row {
  border-radius: 10px !important;
  overflow: hidden !important;
}

.file-checkbox {
  text-align: center;
}

.filter-col {
  margin-top: 30px;
  margin-bottom: 20px;
}

.media-body > p {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.list-media-body > h5 {
  font-size: var(--font-size-m);
  padding-top: 8px;
}

.list-media-body > p {
  font-size: var(--font-size-m);
}

.filter-item > button {
  background: var(--blue-chalk) !important;
  border-color: #fff !important;
  color: #7f56d9;
}

.filters-dropdown {
  border-color: grey !important;
  background-color: transparent !important;
  color: grey;
}
.filters-dropdown:hover {
  color: grey;
}
.dropdown:is(.show) > .filters-dropdown {
  color: grey;
}
.filters-dropdown:after {
  display: none;
}

.form-status-up-to-date {
  background-color: var(--narvik);
  color: grey;
  padding: 7px;
}

.form-status-pending {
  background-color: var(--concrete);
  color: grey;
  padding: 7px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--white-lilac) !important;
}

.card {
  border-radius: 0.65rem !important;
}

.file-view-more {
  cursor: pointer;
}

.stat-up {
  color: green;
}

.stat-down {
  color: red;
}

.avatars-OYw5hJ {
  background-color: transparent;
  height: 24px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 124px;
}

.avatar-wrap-0 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-1 {
  background-color: transparent;
  height: 24px;
  left: 20px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-2 {
  background-color: transparent;
  height: 24px;
  left: 40px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-wrap-3 {
  background-color: transparent;
  height: 24px;
  left: 60px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}
.avatar-wrap-4 {
  background-color: transparent;
  height: 24px;
  left: 80px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-wrap-5 {
  background-color: transparent;
  height: 24px;
  left: 100px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 20px;
}

.avatar-LcVv8q {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-f0f9Ls {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-4I2ic3 {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-UHrqfV {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}
.avatar-hLXsUF {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-B6y5ns {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-Vx0t4z {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-mTcO5l {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-PbkabT {
  background-color: transparent;
  border-radius: 200px;
  height: 27px;
  left: -2px;
  mix-blend-mode: normal;
  overflow: hidden;
  position: relative;
  top: -2px;
  width: 27px;
}

.avatar-yc1H1O {
  background-color: transparent;
  height: 27px;
  left: 0px;
  mix-blend-mode: normal;
  position: absolute;
  top: 0px;
  width: 27px;
}

.avatar-bfxrUs {
  background-color: var(--magnolia);
  border-radius: 200px;
  height: 28px;
  left: 98px;
  mix-blend-mode: normal;
  position: absolute;
  top: -2px;
  width: 28px;
}

.text-i2ip3A {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: 18px;
  mix-blend-mode: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
  width: 24px;
}

.dashboard-nav {
  height: 100vh;
  padding-top: 10vh;
  color: var(--concrete) !important;
}

.dashboard-nav > a {
  color: var(--concrete) !important;
}

.dashboard-nav > a > i {
  padding-right: 10px;
}

.active {
  background-color: var(--oxford-blue) !important;
}

.nav-card-dashboard {
  background-color: var(--oxford-blue);
}

.hr {
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
  border: 0;
  border-top: 1px solid var(--concrete) !important;
}

.avatar-dashboard-nav {
  width: 3vmin;
}

.avatar-user-details {
  /* padding-left: 4vmin; */
  font-size: 0.7rem;
}

/* .avatar-user-logout-detail, .logout {
    height: 1rem;
} */

.avatar-user-logout-detail > a {
  vertical-align: middle !important;
  color: var(--concrete) !important;
}

.dashboard-nav-search {
  background: var(--oxford-blue) !important;
  border-color: var(--oxford-blue) !important;
  color: var(--concrete) !important;
}

.dashboard-nav-search::placeholder {
  color: var(--concrete) !important;
  content: '\F095';
}

/* 

.dashboard-add-dropdown, .dashboard-add-dropdown-menu {
    width:100% !important;
    border: var(--mischka);
}

.dashboard-add-dropdown > button {
    background: var(--white) !important;
    color: grey;
    text-align: left;
    width:100% !important;
    border-color: var(--mischka) !important;
} */

.btn-danger {
  border-color: var(--mischka) !important;
}

.dashboard-add-dropdown > button:after {
  /* position: relative !important;
    right: 0px !important; */
  text-align: right;
  float: right;
  margin-top: 9px;
}

.submit {
  width: 100% !important;
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}

.add-file-media-block {
  padding-left: 15px !important;
  margin-top: 25px;
  margin-bottom: 25px;
}
