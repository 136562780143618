.file-disc {
  letter-spacing: 0px;
  line-height: 20px;
  mix-blend-mode: normal;
  text-align: left;
  white-space: nowrap;
  /* width: auto; */
  margin-top: 16px;
}

.file-icon {
  background: var(--blue-chalk);
  padding: 13px;
  border-radius: 45px;
  color: #7f56d9;
  font-size: 16px;
  position: relative;
  top: 9%;
  left: 25px !important;
}

.file-icon-view {
  top: 19%;
}

.btn-light {
  border: 1px solid #ced4da;
  color: #000 !important;
}

.file-version-view-icon {
  left: 0px !important;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 30px;
}
