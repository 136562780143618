.left-panel {
  /*overflow: hidden;*/
}
.nav-spacer {
  height: 3rem;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.nav-spacer-top {
  height: 3rem;
}
.bi {
  margin-right: 6px;
}

.active {
  color: var(--concrete) !important;
}

a {
  color: var(--concrete) !important;
}

.nav-profile {
  margin-top: 15px;
  margin-bottom: 20px;
}

.logout {
  font-size: 1rem !important;
  /*position: absolute;*/
  /*left: 210px;*/
  /*bottom: 25px;*/
  color: #eaecf0;
}

.avatar-dashboard-nav {
  border-radius: 31px;
  /*position: absolute;*/
  /*bottom: 20px;*/
  margin-right: 5px;
}

.avatar-user-details {
  /*padding-bottom: 5px;*/
  margin-left: 5px;
}

.avatar-user-details > small {
  font-size: 0.5rem;
}

.dropdown-item {
  color: grey !important;
}

.nav-profile {
  position: fixed;
  bottom: 0;
  left: 0;
}
