.right-panel-dashboard {
  margin-top: 15px;
  border-radius: 45px 0px 0px;
  background: #fff;
  min-height: 100vh;
  height:100%
}

.dashboard-main-header {
  padding: 35px;
}

.dashboard-card {
  width: 100% !important;
}

.small-amount {
  color: gray;
}
/*.dashboard {*/
/*    overflow-y: scroll !important;*/
/*}*/
