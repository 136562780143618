.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--bs-table-bg) !important;
}
.th-padding {
  padding: 0 35px !important;
}
ul.pill-ul {
  list-style: none;
  margin: 16px auto;
}

.pill {
  border-radius: 15px;
  background-color: #f2f4f7;
  padding: 5px 0;
  margin: 0 25px;
  text-align: center;
}

.pill-active {
  background-color: #ecfdf3;
  color: #027a48 !important;
}

.pill-team {
  background-color: #f9f5ff;
  color: #6941c6 !important;
}

.pill-active::before {
  content: '\2022';
  color: #027a48 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.pill-undefined::before {
  content: '\2022';
  color: #344054 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.user-table-space {
  margin-top: 35px;
}