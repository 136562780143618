.nav-tabs {
  border-bottom: 1px solid #f2f4f7;
  margin-bottom: 40px;
}

.nav-tabs .nav-item div {
  color: #667085 !important;
  background-color: #fff !important;
  border-color: #fff #fff #f2f4f7;
}

.nav-tabs .nav-item .active,
.nav-tabs .nav-item div:hover {
  color: #6941c6 !important;
  background-color: #fff !important;
  border-color: #fff #fff #6941c6;
}

hr {
  background-color: #cfd4dc;
}

.hr-feild-list {
  margin-bottom: 60px;
}

.input-settings {
  border-radius: 5px;
  border-top-color: #f2f4f7 !important;
  border-right-color: #f2f4f7 !important;
  border-bottom-color: #f2f4f7 !important;
  border-left-color: #f2f4f7 !important;
}
.settings-input {
  width: 100%;
  padding: 5px;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  border-top-color: #667085 !important;
  border-right-color: #667085 !important;
  border-bottom-color: #667085 !important;
  border-left-color: #667085 !important;
}
.country-toggle {
  background-color: #ffffff !important;
  width: 100%;
  text-align: left !important;
  color: #101828;
  border-width: 2px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  border-top-color: #667085 !important;
  border-right-color: #667085 !important;
  border-bottom-color: #667085 !important;
  border-left-color: #667085 !important;
}
.country-item {
  width: 100% !important;
}
.country-dropdown {
  position: relative !important;
  text-align: left !important;
}
.pWordInputs {
  margin-bottom: 50px;
}
.pWordBtn {
  /*width: 80px;*/
}

.pWordBtn-cancel {
  background-color: #eaecf0;
  color: #0f1728;
}

.pWordBtn-save,
.pWordBtn-save:active,
.pWordBtn-save:hover {
  background-color: #7e56d8;
  margin-left: 10px;
  color: #ffffff;
}
.user-avatar-td {
  width: 60px;
}
.user-avatar {
  border-radius: 31px;
  width: 4rem;
}

.user-avatar-img {
  width: 4rem;
}

@media screen and (max-width: 1000px) {
  .user-avatar-img {
    width: 3rem;
  }
}
