.workflows-tr > td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.closeBtn {
    position: absolute;
    top: 20px;
    right: 30px;
}

.workflow-file-icon {
    background: var(--blue-chalk);
    padding: 7px 12px !important;
    border-radius: 73px !important;
    color: #7f56d9 !important;
    font-size: 11px !important;
    position: relative;
    top: 9%;
    left: 25px !important;
}

.workflow-task-icon {
    background: var(--blue-chalk);
    padding: 7px 10px !important;
    border-radius: 73px !important;
    color: #7f56d9 !important;
    font-size: 12px !important;
    position: relative;
    top: 9%;
    left: 25px !important;
}

.workflow-description {
    max-width: 540px;
    font-size: 14px;
    color: #667085;
}
.workflow-status {
    background-color: var(--narvik);
    color: darkgray !important;
    padding: 7px;
    text-decoration: none;
}

.workflow-input {
    margin-bottom: 10px;
}

.workflows-title {
    padding-left: 24px;
}

.add-step-submit {
    background-color: #667084 !important;
    border-color: #667084 !important;
}

.add-form-header-workflow {
    margin-left: -10px;
}

.completed {
    background-color: var(--oxford-blue) !important;
}

svg.Mui-active > circle {
    background-color: #667084 !important;
}

.workflow-save {
    flex: fit-content;
    flex-align: flex-end;
    position: absolute;
    bottom: 10px;
    width: 100% !important;
}

.btn-workflow-step {
    flex: fit-content;
    border-radius: 6px;
    color: #7f56d9 !important;
    width: 100%;
    text-align: left;
}

.btn-workflow-step:hover {
    border-radius: 6px;
    background: #F9F5FF;
    color: #fff;
}

.btn-workflow-step-more {
    color: #7f56d9 !important;
}

.add-step-cancel {
    background: none !important;
    border-color: var(--oxford-blue) !important;
    color: var(--oxford-blue) !important;
}
