.overlay,
.overlay-file {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  backdrop-filter: blur(8px) brightness(100%);
  background-color: #3440534c;
  mix-blend-mode: normal;
  z-index: 10000;
}

.hide {
  display: none;
}

.add-form {
  width: 650px;
  min-height: 550px;
  /* max-height: 550px; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30vh;
  margin-left: -325px;
  background: var(--white) !important;
  padding: 20px;
  border-radius: 10px;
}

.add-btn {
  height: 40px;
  background-color: #7f56d9 !important;
}

.add-btn-team {
  height: 40px;
  background-color: #7f56d9 !important;
}

.add-btn-user {
  height: 40px;
  background: var(--white) !important;
  color: black;
  text-align: center;
  width: 100% !important;
  border-color: var(--mischka) !important
}