.delete-icon:hover,
.edit-icon {
  cursor: pointer;
  color: #101828 !important;
  text-shadow: 2px 2px whitesmoke;
}

thead > tr > th {
  font-size: 12px;
  color: grey;
}

.file-icon {
  background: var(--blue-chalk);
  padding: 1rem 1rem;
  border-radius: 45px;
  color: #7f56d9;
  font-size: 19px;
  position: relative;
  top: 20px;
  left: -5px;
}

.filter-item {
  margin-right: 10px;
}
