:root {
  --black: #000000;
  --blue-chalk: #f4ebff;
  --concrete: #f2f3f6;
  --fuchsia-blue: #7e56d8;
  --gallery: #eaecf0;
  --magnolia: #f9f5ff;
  --mirage: #0f1728;
  --mischka: #cfd4dc;
  --narvik: #ebfdf2;
  --oxford-blue: #344053;
  --pale-sky: #667084;
  --salem: #027947;
  --storm-gray: #667085;
  --white: #ffffff;
  --white-lilac: #f8f9fb;

  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 18px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 36px;

  --font-family-inter: 'Inter', Helvetica;
}
.inter-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-pale-sky-16px {
  color: var(--pale-sky);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-oxford-blue-14px {
  color: var(--oxford-blue);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-concrete-16px {
  color: var(--concrete);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-fuchsia-blue-12px {
  color: var(--fuchsia-blue);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-pale-sky-12px {
  color: var(--pale-sky);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-salem-12px {
  color: var(--salem);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-storm-gray-16px {
  color: var(--storm-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-mirage-16px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-mirage-16px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.inter-semi-bold-mirage-36px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.inter-medium-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.border-1-5px-white {
  border: 1.5px solid var(--white);
}

.border-1px-mischka {
  border: 1px solid var(--mischka);
}

.border-1px-fuchsia-blue {
  border: 1px solid var(--fuchsia-blue);
}

.border-2px-white {
  border: 2px solid var(--white);
}

.border-1px-gallery {
  border: 1px solid var(--gallery);
}
