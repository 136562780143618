@import url('https://fonts.googleapis.com/css?family=Inter:400,500,600');

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url('https://px.animaapp.com/621fd079114f27e7693bd4ab.621fd07b5ad79b5f63622c5e.T06oT8w.hcp.png');

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css');

.screen textarea:focus,
.screen input:focus {
  outline: none;
}

.screen * {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.screen div {
  -webkit-text-size-adjust: none;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
